import React, { useContext } from 'react';
import './App.css';
import Web3Context, { Web3Context as context } from './Components/Web3Context/Web3Context';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from './Layout/Layout';
import HomePage from './Pages/HomePage';
import AdminPage from './Pages/AdminPage';
import DebugPage from './Pages/DebugPage';

function App() {

    return (
        <div>
            <Web3Context>
                <Layout>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/debug" element={<DebugPage />} />

                        {/* default home route */}
                        {/* <Route path="/" element={<Navigate to="/home" />} /> */}

                        {/* route catch-all */}
                        <Route path="*" element={<Navigate to="/" />} />

                        {/* <Route path="/r/:referrer" element={<ReferralPage />}></Route> */}
                        {/* parameterised routes */}
                        {/* <Route path="/nfts" element={<NftsPage />}>
                            <Route path=":nftContractAddress/:nftId" element={<NftsPage />} ></Route>
                            </Route> 
                        */}

                        {/* conditional route */}
                        {/* {useContext(context).isAdmin == true ? */}
                        {/* <Route path="/admin" element={<AdminPage />} /> */}
                        {/* : null} */}

                    </Routes>
                </Layout>
            </Web3Context>
        </div>
    );
}

export default App;
