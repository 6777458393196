import React, { useContext, useState } from 'react';
import { abbreviateAddress, round, roundAndFormatNumber } from '../Common/HelperFunctions';
import { Web3Context } from '../Components/Web3Context/Web3Context';


function DebugPage() {
    const { web3Connected, providerName, walletAddress } = useContext(Web3Context);

    return (
        <>
            <main className="flex flex-col w-100 text-left flex-1 relative focus:outline-none">
                <h1 className="text-primary-500  text-3xl font-bold">Debug</h1>
                <div className="mt-6 text-white  w-full">
                    <h2 className="text-primary-500 text-2xl font-bold">Browser</h2>
                    <table className="mt-2 w-full border">
                        <thead>
                            <tr className="border-b">
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>User Agent</td><td>{navigator.userAgent}</td></tr>
                            <tr><td>Language</td><td>{navigator.language}</td></tr>
                            <tr><td>Vendor</td><td>{navigator.vendor}</td></tr>
                            <tr><td>Timezone</td><td>{Intl.DateTimeFormat().resolvedOptions().timeZone}</td></tr>
                        </tbody>
                    </table>

                    <h2 className="mt-6 text-primary-500 text-2xl font-bold">App</h2>
                    <table className="mt-2 w-full border">
                        <thead>
                            <tr className="border-b">
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>window.ethereum</td><td>{((window as any).ethereum != null).toString()}</td></tr>
                            <tr><td>Selected Provider</td><td>{providerName}</td></tr>
                            <tr><td>Provider Connected</td><td>{web3Connected.toString()}</td></tr>
                            <tr><td>Wallet Address</td><td><a href={`https://www.bscscan.com/address/${walletAddress}`} target="_blank">{walletAddress}</a></td></tr>
                            
                        </tbody>
                    </table>
                </div>
                {/* <div className="max-w-lg grid gap-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-5 lg:max-w-none mt-6"> */}
                {/* </div> */}
            </main>
        </>
    );
}

export default DebugPage;