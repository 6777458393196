

import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { abbreviateAddress } from '../Common/HelperFunctions';
import ConnectButton from '../Components/ConnectButton/ConnectButton';
import { Web3Context } from '../Components/Web3Context/Web3Context';

import ImageLogo from '../Images/logo_full.svg';
// import { ReactComponent as IconLogoFull } from '../Images/logo_full.svg';


interface TopNavProps {
}

const TopNav: FC<TopNavProps> = (props) => {
    const { connectWeb3, disconnectWeb3, web3Connected, walletAddress, bnbUsdtPrice, isAdmin } = useContext(Web3Context);

    const navLinks = [
        { name: "Dashboard", link: "/home" },
        { name: "Whitepaper", link: "https://gtr.uk/wp-content/uploads/2022/03/GTR-WhitePaper.V2.pdf" },
        { name: "Homepage", link: "https://gtr.uk" },
        // { name: "Affiliate", link: "/affiliate" },
    ];

    function isActive(link: string): boolean {
        var lower = link.toLowerCase();
        if (lower.startsWith("http")) return false;
        else if (document.location.pathname != "/" && document.location.pathname.toLowerCase().startsWith(lower)) return true;
        else return false;
    }

    return (
        <header className="p-6 bg-white bg-opacity-5 drop-shadow-sm overflow-hidden h-20">
            <div className="mx-auto ">
                <div className="text-left flex px-4 md:px-10 flex flex-row">

                    <div className="flex-grow md:flex-grow-0 md:flex">
                        <img src={ImageLogo} className="h-12 object-contain -mt-2 md:h-14 md:-mt-4 flex-col" />
                        {/* <div className="border-l-4 border-white h-full mx-8 -mt-2"></div>
                        <div className="text-gradient-horizontal text-4xl -mt-2 ">PRESALE ROUNDS</div> */}
                    </div>

                    <div className="hidden md:flex-grow md:flex ">
                        <div className="m-auto flex">
                            {navLinks.map((x) => <a key={x.link} href={x.link} target={x.link.startsWith("http") ? "_blank" : ""}><div className={"px-4 text-white text-lg text-center " + (isActive(x.link) ? "activelink font-bold" : "")}>{x.name}&nbsp;&nbsp;{x.link.startsWith("http")? <FontAwesomeIcon icon={faUpRightFromSquare} /> : null}</div></a>)}
                        </div>
                    </div>


                    {/* {props.sideBarCollapsed == false ? */}
                    <ConnectButton className="flex-none h-12 -mt-2 ml-4 md:px-5 px-2 pt-2 font-bold" connectWeb3={connectWeb3} disconnectWeb3={disconnectWeb3} web3Connected={web3Connected} walletAddress={walletAddress} />
                    {/* : null} */}
                </div>

                <div className="hidden sm:text-md md:text-md lg:text-lg text-white flex">
                    {/*&nbsp;<a href="https://coinmarketcap.com/currencies/" target="_blank"><IconCmc style={{ fill: "white", height: "20px" }} className="md:mt-1 -sm:mt" /></a>*/}</div>
            </div>
        </header>
    );
}

export default TopNav;
