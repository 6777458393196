export type SelectOption = {
    value: string;
    label: string;
    image?: string;
};

type SelectProps = {
    options: SelectOption[] | undefined;
    value: SelectOption | undefined;
    otherClasses?: string;
    innerOtherClasses?: string;
    accent?: boolean;
    mobileHideText?: boolean;
    onChange: (value: string) => void;
    disabled?: boolean;
    placeholderText?: string;
};

const StyledSelect = (props: SelectProps) => {
    function isSelected(value: string): boolean {
        var ret = props.value?.value == value;
        return ret;
    }

    return (
        <div className={`${props.otherClasses} ${props.disabled == true ? "disabled" : ""}`}>
            <ul className="grid grid-cols-3 font-bold">
                {props.options?.map(x => (
                    <li key={`select_${x.value}`} className={`pt-2.5 mx-auto border rounded-xl h-12 mb-2 mt-4 px-4 pr-5 md:w-32 select-none cursor-pointer ${isSelected(x.value) ? "bg-primary border-primary text-slate-900" : null}`} onClick={() => props.onChange(x.value)}>
                        {!!x?.image && (<>
                            <div className="m-auto">
                                <img src={x.image} className="h-6 absolute mx-1  rounded-full" />

                                <div className={`overflow-y-hidden ${!!x.image ? "pl-8" : ""} h-16 ${props.mobileHideText ? "hidden lg:inline-block" : ""}`}>
                                    {x.label}
                                </div>
                            </div>
                        </>)}
                    </li>
                ))}
            </ul>
        </div>

        // <div
        //   className={`dropdown ${props.otherClasses} ${
        //     props.disabled == true ? "disabled" : null
        //   }`}
        // >
        //   <label
        //     tabIndex={0}
        //     className={`select relative pt-2.5 min-w-full ${
        //       props.accent ? "select-accent" : "select-bordered"
        //     } bg-transparent text-white  overflow-y-hidden ${
        //       props.innerOtherClasses
        //     } ${props.disabled == true ? "disabled" : null}`}
        //   >
        //     {!!props.value?.image && (
        //       <img
        //         src={props.value.image}
        //         className="h-10 absolute mx-1 -mt-1.5 -ml-3 rounded-full"
        //       />
        //     )}
        //     <div
        //       className={`overflow-y-hidden ${
        //         !!props.value?.image ? "pl-10" : ""
        //       } h-16 ${props.mobileHideText ? "hidden lg:inline-block" : ""}`}
        //     >
        //       {!!props.value
        //         ? props.value.label
        //         : props.placeholderText
        //         ? props.placeholderText
        //         : "Select..."}
        //     </div>
        //   </label>
        //   <ul
        //     tabIndex={0}
        //     className={`dropdown-content menu menu-vertical bg-base-100 w-64 p-1 rounded-box max-h-64 flex-nowrap overflow-y-scroll`}
        //   >
        //     {props.options?.map((option) => (
        //       <li
        //         key={option.value}
        //         onClick={() => {
        //           props.onChange(option.value);
        //           try {
        //             (document.activeElement as HTMLElement).blur();
        //           } catch (e) {}
        //         }}
        //       >
        //         <a>
        //           {option.image && (
        //             <img
        //               src={option.image}
        //               className="h-6 inline mx-1 rounded-full"
        //             />
        //           )}
        //           {option.label}
        //         </a>
        //       </li>
        //     ))}
        //   </ul>
        // </div>
    );
};

export default StyledSelect;
