import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { dateFromUtcEpoch, groupBy, log, secondsToDateTime } from '../../Common/HelperFunctions';
import WalletConnectProvider from "@walletconnect/web3-provider";

import { AllowListEntry, BinanceTickerApiResponse, PresaleRound, PresaleTransaction, SmartContractAddressMap, TokenInformationResponse } from '../../Common/Models';
import { get } from 'https';

interface Web3ProviderProps { }

export type Web3ProviderContextType = {
    Web3Connection: Web3 | null | undefined,
    connectWeb3: (provider: string | undefined) => void;
    disconnectWeb3: () => void;
    web3Connected: boolean;
    currentChainId: number;
    walletAddress: string;
    providerName: string;

    isAdmin: boolean;

    busdBalance: number;
    usdcBalance: number;
    usdtBalance: number;
    bnbBalance: number;
    bnbUsdtPrice: number;
    busdAllowance: number;
    usdtAllowance: number;
    usdcAllowance: number;
    allowListEntries: AllowListEntry[];
    approveBusd: () => Promise<void>;
    approveUsdt: () => Promise<void>;
    approveUsdc: () => Promise<void>;

    setReferrerString: (referrer: string) => Promise<void>;

    //presale
    presaleTransactions: PresaleTransaction[],
    presaleRounds: PresaleRound[],
    contribute: (round: number, asset: string, amount: number, reference: string) => Promise<void>;
}

export const Web3Context = React.createContext<Web3ProviderContextType>({
    Web3Connection: null,
    currentChainId: 1,
    connectWeb3: () => console.warn('method not set up...'),
    disconnectWeb3: () => console.warn('method not set up...'),
    web3Connected: false,
    walletAddress: "",
    providerName: "",

    isAdmin: false,

    busdBalance: 0,
    usdtBalance: 0,
    usdcBalance: 0,
    bnbBalance: 0,
    bnbUsdtPrice: 0,
    busdAllowance: 0,
    usdcAllowance: 0,
    usdtAllowance: 0,
    allowListEntries: [],
    approveBusd: async () => console.warn("method not set up..."),
    approveUsdt: async () => console.warn("method not set up..."),
    approveUsdc: async () => console.warn("method not set up..."),

    presaleTransactions: [],
    presaleRounds: [],
    setReferrerString: async (referrer: string) => console.warn("method not set up..."),
    contribute: async (round, asset, amount, reference) => console.warn('method not set up...'),
});

const smartContractAddressMap: SmartContractAddressMap[] = [
    {
        chainId: 1,
        chainName: "mainnet",
        factor: 0.000000000000000001,
        presaleContractAbi: require("./abi/GtrPresaleV1.abi.json"),
        presaleContractAddress: "0x69dfa3F1266B97b6Fa6044c8358531E029f2131c",
        tokenAbi: require("./abi/IERC20.abi.json"),
        usdtTokenAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        busdTokenAddress: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
        usdcTokenAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",

    }
];

function getChainAddresses(chainId: number): SmartContractAddressMap {
    var chain = smartContractAddressMap.filter((x) => x.chainId == chainId)[0];
    return chain;
}

const Web3Provider: React.FC<Web3ProviderProps> = (props) => {
    var _contracts: any = {};

    const [currentChainId, setCurrentChainId] = useState(56);
    const [web3Connection, setWeb3Connection] = useState<Web3>();
    const [web3Connected, setWeb3Connected] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [providerName, setProviderName] = useState("");

    //pricing
    const [bnbBalance, setBnbBalance] = useState(0);
    const [bnbUsdtPrice, setbnbUsdtPrice] = useState(0);
    const [busdAllowance, setBusdAllowance] = useState(0);
    const [usdcAllowance, setUsdcAllowance] = useState(0);
    const [usdtAllowance, setUsdtAllowance] = useState(0);

    const [busdBalance, setBusdBalance] = useState(0);
    const [usdtBalance, setUsdtBalance] = useState(0);
    const [usdcBalance, setUsdcBalance] = useState(0);

    //flags
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    //presale
    const [presaleTransactions, setPresaleTransactions] = useState<PresaleTransaction[]>([]);
    const [presaleRounds, setPresaleRounds] = useState<PresaleRound[]>([]);
    const [referrer, setReferrer] = useState<string>();
    const [allowListEntries, setAllowListEntries] = useState<AllowListEntry[]>([]);

    //STEP 1 - once we have connected a wallet, get the address
    useEffect(() => {
        if (web3Connection != null) {
            log("useEffect[web3Connection]", "Loading wallet...");

            //get wallet address
            web3Connection.eth.getAccounts().then(function (accounts: any) {
                var account = accounts[0];
                log("useEffect[web3Connection]", `Account selected: ${account}`);

                if (account.toLowerCase() != walletAddress.toLowerCase()) {
                    setWalletAddress(account);
                    if (providerName != "walletconnect") {
                        localStorage.setItem('lastWalletAddress', account);
                        localStorage.setItem('lastWalletProvider', providerName);
                    }
                }
            });
        }
    }, [web3Connection]);


    //STEP 2 - once we have set the wallet, we'll enumerate all the plans from the contracts
    useEffect(() => {
        if (web3Connected == true && web3Connection != null) {
            fetchAllData(walletAddress);
        }
    }, [walletAddress]);

    //reviving last wallet address
    useEffect(() => {
        var address = localStorage.getItem('lastWalletAddress') || '';
        var walletProvider = localStorage.getItem('lastWalletProvider') || '';
        setReferrer(localStorage.getItem('referrer') || '');

        if (address != '') { connectWeb3(walletProvider); }
    }, []);

    function getContract(address: string, abi: any): any {
        if (web3Connected == true && web3Connection != null && address != "") {
            if (_contracts[address] == null) {

                const contract = new web3Connection.eth.Contract(abi, address);
                _contracts[address] = contract;

            }
            return _contracts[address];
        }
        return null;
    }

    async function connectWeb3(mode: string = "walletconnect") {
        var web3: any;
        var provider: any;
        setProviderName(mode);

        if (mode == "walletconnect") {
            log("connectWeb3", "Initiating session with WalletConnect...");
            provider = new WalletConnectProvider({
                rpc: {
                    1: "https://rpc.ankr.com/eth",
                },
                chainId: 1,
                clientMeta: {
                    name: "Ghost Trader Presale",
                    description: "Participate in Ghost Trader's Ethereum presale.",
                    url: "https://presale.gtr.uk",
                    icons: [
                        "https://gtr.uk/wp-content/uploads/2021/11/cropped-Ghost-Favicon-180x180.png"
                    ]
                }
            });
        }
        else if (mode == "metamask") {
            log("connectWeb3", "Initiating session with MetaMask...");
            provider = (window as any).ethereum;
        }

        //instantiate web3 client
        var result = await provider.enable();
        log("connectWeb3", result);
        web3 = new Web3(provider);

        if (web3 != null) {
            try {
                log("connectWeb3", "Web3 access granted!");
                setWeb3Connected(true);
                setWeb3Connection(web3 as any);

                log("connectWeb3", "Web3 loaded correctly...");
                var chainId = await web3.eth.getChainId();
                setCurrentChainId(chainId);

                //----------------------------------
                if (chainId !== 1) {
                    try {
                        await provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: web3.utils.toHex(1) }]
                        });
                    } catch (err: any) {
                        // This error code indicates that the chain has not been added to MetaMask
                        if (err.code === 4902 || err?.data?.orginalError?.code === 4902) {
                            console.log("You do not have a chain set up for Ethereum.");
                        }
                    }
                }
                //----------------------------------

                var chainDetails = getChainAddresses(chainId);
                if (!chainDetails) {
                    log("connectWeb3", "No chain details found...");
                    return;
                }

                //subscribe to provider events
                provider.on('accountsChanged', function (accounts: any) {
                    web3 = null;
                    provider = null;

                    connectWeb3(mode);
                });
                provider.on('disconnect', (code: number, reason: string) => {
                    console.log(code, reason);
                    disconnectWeb3();
                });

            } catch (e) {
                log("connectWeb3", "Web3 access denied...");
            }
        }
    }

    async function disconnectWeb3() {
        log("disconnectWeb3", `Disconnecting...`);
        setWeb3Connected(false);
        setWalletAddress("");
        localStorage.setItem('lastWalletAddress', "");
        localStorage.setItem('lastWalletProvider', "");
        localStorage.setItem('walletconnect', "");
        localStorage.setItem('referrer', "");
        window.location.reload();
    }

    //=======================================================================


    async function fetchAllData(address: string) {
        //exit logic on loop in case address has changed in web3 provider
        if (address.toLowerCase() != walletAddress.toLowerCase()) { return; }
        if (web3Connection != null) {
            var addresses = await web3Connection.eth.getAccounts();
            if (addresses[0].toLowerCase() != address.toLowerCase()) { return; }
        }

        //functions to refresh data
        fetchAdmins();
        // fetchBnbBalance();
        // fetchBnbUsdtTicker();
        fetchPresaleDeposits();
        fetchPresaleRounds();
        fetchBusdApprovalAmount();
        fetchUsdtApprovalAmount();
        fetchUsdcApprovalAmount();
        fetchTokenBalances();
        fetchAllowlistRounds();

        //re-run the loop
        setTimeout(() => fetchAllData(address), 30000);
    }

    async function fetchBnbBalance() {
        if (web3Connected == true && web3Connection != null) {
            // log("fetchBnbBalance", "Retrieving wallet token balance...");

            var balance = await web3Connection.eth.getBalance(walletAddress);
            // log("fetchBnbBalance", balance);

            var balanceNumber = Number(balance);
            // log("fetchBnbBalance", `Input: ${balanceNumber}`);

            setBnbBalance(balanceNumber / (1000000000000000000));
            // log("fetchBnbBalance", `Adjusted: ${bnbBalance}`);
        }
    }

    async function fetchAdmins() {
        //ideally retrieve this from an external contract such as multi-sig wallet
        var admins: string[] = [
            "0x9bf0800193825e3389B14EB69abDE07dd932b6AC" //jourdan
        ];

        var match = admins.filter((x) => x.toLowerCase() == walletAddress.toLowerCase());
        if (match.length > 0) {
            setIsAdmin(true);
        }
    }

    async function fetchBnbUsdtTicker() {
        const tickerUrl = "https://api.binance.com/api/v3/avgPrice?symbol=BNBUSDT";
        const res: BinanceTickerApiResponse = await (await fetch(tickerUrl)).json();
        setbnbUsdtPrice(Number(res.price));
    }

    async function fetchPresaleDeposits() {
        const url = `https://gtr-backend.azurewebsites.net/api/v1/presale/contributions/${walletAddress}`;
        const res: PresaleTransaction[] = await (await fetch(url)).json();
        setPresaleTransactions(res);
    }

    async function fetchAllowlistRounds() {
        const url = `https://gtr-backend.azurewebsites.net/api/v1/presale/allowlist/${walletAddress}`;
        const res: AllowListEntry[] = await (await fetch(url)).json();
        setAllowListEntries(res);
    }

    async function fetchBusdApprovalAmount() {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            const contract = getContract(context.busdTokenAddress, context.tokenAbi);

            var rawAmount: number = await contract.methods.allowance(walletAddress, context.presaleContractAddress).call({ from: walletAddress });
            var decimals: number = await contract.methods.decimals().call({ from: walletAddress });
            var adjustedAmount = rawAmount / (10 ** decimals);
            log("fetchBusdApprovalAmount", `Raw amount: ${rawAmount}  decimals: ${decimals}  adjustedAmount: ${adjustedAmount}`);
            setBusdAllowance(adjustedAmount);
        }
    }

    async function fetchUsdtApprovalAmount() {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            const contract = getContract(context.usdtTokenAddress, context.tokenAbi);

            var rawAmount: number = await contract.methods.allowance(walletAddress, context.presaleContractAddress).call({ from: walletAddress });
            var decimals: number = await contract.methods.decimals().call({ from: walletAddress });
            var adjustedAmount = rawAmount / (10 ** decimals);
            log("fetchUsdtApprovalAmount", `Raw amount: ${rawAmount}  decimals: ${decimals}  adjustedAmount: ${adjustedAmount}`);
            setUsdtAllowance(adjustedAmount);
        }
    }

    async function fetchUsdcApprovalAmount() {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            const contract = getContract(context.usdcTokenAddress, context.tokenAbi);

            var rawAmount: number = await contract.methods.allowance(walletAddress, context.presaleContractAddress).call({ from: walletAddress });
            var decimals: number = await contract.methods.decimals().call({ from: walletAddress });
            var adjustedAmount = rawAmount / (10 ** decimals);
            log("fetchUsdcApprovalAmount", `Raw amount: ${rawAmount}  decimals: ${decimals}  adjustedAmount: ${adjustedAmount}`);
            setUsdcAllowance(adjustedAmount);
        }
    }

    async function fetchTokenBalances() {
        const context = getChainAddresses(currentChainId);

        var usdt = await fetchTokenBalance(context.usdtTokenAddress);
        setUsdtBalance(usdt);
        var usdc = await fetchTokenBalance(context.usdcTokenAddress);
        setUsdcBalance(usdc);
        var busd = await fetchTokenBalance(context.busdTokenAddress);
        setBusdBalance(busd);
    }

    async function fetchTokenBalance(contractAddress: string): Promise<number> {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            const contract = getContract(contractAddress, context.tokenAbi);

            var rawAmount: number = await contract.methods.balanceOf(walletAddress).call({ from: walletAddress });
            var decimals: number = await contract.methods.decimals().call({ from: walletAddress });
            var adjustedAmount = rawAmount / (10 ** decimals);
            log("fetchTokenBalance", `Raw amount: ${rawAmount}  decimals: ${decimals}  adjustedAmount: ${adjustedAmount}`);
            return adjustedAmount;
        }
        else return 0;
    }

    async function approveBusd() {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            const contract = getContract(context.busdTokenAddress, context.tokenAbi);
            var amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

            await contract.methods.approve(context.presaleContractAddress, amount).send({ from: walletAddress });
            await fetchBusdApprovalAmount();
        }
    }

    async function approveUsdt() {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            const contract = getContract(context.usdtTokenAddress, context.tokenAbi);
            var amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

            await contract.methods.approve(context.presaleContractAddress, amount).send({ from: walletAddress });
            await fetchUsdtApprovalAmount();
        }
    }

    async function approveUsdc() {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            const contract = getContract(context.usdcTokenAddress, context.tokenAbi);
            var amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

            await contract.methods.approve(context.presaleContractAddress, amount).send({ from: walletAddress });
            await fetchUsdcApprovalAmount();
        }
    }

    async function fetchPresaleRounds() {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            const fullPrice = 0.12;
            const contract = getContract(context.presaleContractAddress, context.presaleContractAbi);
            const roundNames: string[] = ["ROUND 1", "BONUS ROUND",];
            const roundVesting: string[] = ["6 MONTHS", "6 MONTHS", "6 MONTHS", "1 MONTH W/ 50% EARLY RELEASE"];
            const roundSpecial: boolean[] = [false, false, true, true, true];
            const rounds: PresaleRound[] = [];

            var round0bal = await contract.methods._roundContributionBalances(0).call({ from: walletAddress });
            // // add seed round manually
            // rounds.push({
            //     number: 0,
            //     name: "ROUND 1",
            //     balance: round0bal * context.factor,
            //     maximum: 76000,
            //     activeFrom: new Date("2023-01-22"),
            //     closed: false,
            //     price: 0.019,
            //     special: false,
            //     vesting: "0 MONTHS",
            //     whitelisted: true
            // });

            // rounds.push({
            //     number: 1,
            //     name: "OTC 1",
            //     balance: 3 * context.factor,
            //     maximum: 10000,
            //     activeFrom: new Date("2023-01-22"),
            //     closed: false,
            //     price: 0.019,
            //     special: false,
            //     vesting: "0 MONTHS",
            //     whitelisted: true
            // });

            for (var i = 0; i < 2; i++) {
                try {
                    var maximum: number = await contract.methods._roundContributionCaps(i).call({ from: walletAddress });
                    // var minimum: number = await contract.methods._roundContributionMinimums(i).call({ from: walletAddress });
                    var active: number = await contract.methods._roundActiveTimestamps(i).call({ from: walletAddress });
                    var balance: number = await contract.methods._roundContributionBalances(i).call({ from: walletAddress });
                    // var whitelisted: boolean = await contract.methods._roundWhitelistAddresses(i, walletAddress).call({ from: walletAddress });
                    // var whitelisted = allowListEntries.find(x => x.roundId == i)?.isAllowed ?? false; 
                    var factor = context.factor;

                    rounds.push({
                        number: i,
                        name: roundNames[i],
                        balance: balance * factor,
                        maximum: maximum * factor,
                        activeFrom: dateFromUtcEpoch(active),
                        // minimum: minimum * factor,
                        closed: new Date() < new Date(active) || balance * factor == maximum * factor,
                        price: 0.019,
                        vesting: roundVesting[i],
                        special: roundSpecial[i],
                        // whitelisted: whitelisted,
                    });
                }
                catch (ex) { console.error(ex); }
            }

            setPresaleRounds(rounds);
        }
    }

    async function contribute(round: number, asset: string, amount: number): Promise<void> {
        const context = getChainAddresses(currentChainId);
        if (web3Connection != null) {
            var address = "";
            if (asset.toLowerCase() == "usdt") address = context.usdtTokenAddress;
            else if (asset.toLowerCase() == "usdc") address = context.usdcTokenAddress;
            else if (asset.toLowerCase() == "busd") address = context.busdTokenAddress;
            log("contribute", `${asset.toLowerCase()} : ${address}`);

            const presaleContract = new web3Connection.eth.Contract(context.presaleContractAbi, context.presaleContractAddress);
            const tokenContract = new web3Connection.eth.Contract(context.tokenAbi, address);
            var decimals: number = await tokenContract.methods.decimals().call({ from: walletAddress });
            
            log("contribute", `Decimals ${decimals}`);
            var value1 = "0x" + (amount * Math.pow(10, decimals)).toString(16);
            log("contribute", value1);
            var value = Web3.utils.toBN(value1);
            log("contribute", `Normalised value: ${value}`);
            log("contribute", `Sending contribute tx referred by: ${referrer}`);
            await presaleContract.methods.contribute(round, walletAddress, address, value, referrer).send({ from: walletAddress });

            setTimeout(async () => {
                await fetchPresaleRounds();
                fetchPresaleDeposits();
            }, 1500);
        }
    }

    async function approveContract(baseContract: string, walletAddress: string, spender: string, amount: string) {
        if (web3Connection != null) {
            const abi = require("./abi/IERC20.abi.json");
            const contract = new web3Connection.eth.Contract(abi, baseContract);

            await contract.methods.approve(spender, amount).send({ from: walletAddress });
        }
    }

    async function setReferrerString(ref: string): Promise<void> {
        if (ref && !referrer && web3Connected) {
            log("setReferrerString", `Setting referrer to: ${ref}`);
            localStorage.setItem('referrer', ref);
            setReferrer(ref);
        }
        else if (ref == referrer) { }
        else {
            log("setReferrerString", `Referrer '${referrer}' already exists. Can't add new referrer '${ref}'`);
        }
    }

    return (
        <Web3Context.Provider
            value={{
                Web3Connection: web3Connection,
                currentChainId: currentChainId,
                connectWeb3: connectWeb3,
                disconnectWeb3: disconnectWeb3,
                web3Connected: web3Connected,
                walletAddress: walletAddress,
                providerName: providerName,

                isAdmin: isAdmin,

                busdBalance: busdBalance,
                usdcBalance: usdcBalance,
                usdtBalance: usdtBalance,
                bnbBalance: bnbBalance,
                bnbUsdtPrice: bnbUsdtPrice,
                busdAllowance: busdAllowance,
                usdcAllowance: usdcAllowance,
                usdtAllowance: usdtAllowance,
                allowListEntries: allowListEntries,
                approveBusd: approveBusd,
                approveUsdc: approveUsdc,
                approveUsdt: approveUsdt,

                presaleTransactions: presaleTransactions,
                presaleRounds: presaleRounds,
                contribute: contribute,
                setReferrerString: setReferrerString,
            }}
        >
            {props.children}
        </Web3Context.Provider>
    );
}

export default Web3Provider;