import React, { FC, useState } from "react";
import SideNav from "./SideNav";
import TopNav from "./TopNav";

const Layout: FC = (props) => {
    // const [collapsedSidebar, setCollapsedSidebar] = useState(((localStorage.getItem('collapsedSideBar') ?? (window.innerWidth < 640).toString()) === 'true') ?? window.innerWidth < 640);

    return (
        <div className="h-screen flex flex-1 ">
            {/* <SideNav sideBarCollapsed={collapsedSidebar}
                setSideBarCollapsed={() => setCollapsedSidebar(x => {
                    localStorage.setItem('collapsedSideBar', (!x).toString());
                    return !x;
                })}
            /> */}
            <div className={"flex flex-col w-100 sm:flex-1"}>
                <TopNav />
                <div className={"md:pl-10 md:pr-10 sm:flex flex-col w-100 flex-1 px-3 lg:px-8 overflow-y-auto py-4 bg-secondary"}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default Layout;