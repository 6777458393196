import React, { useContext, useEffect, useState } from 'react';
import { Web3Context } from '../Components/Web3Context/Web3Context';
import Round from '../Components/Round/Round';
import { Asset, PresaleRound, PresaleTransaction } from '../Common/Models';
import ImageExoLogo from '../Images/logo_full.svg';
import ImageCryptonairzLogo from '../Images/logo_full.svg';
import { useSearchParams } from 'react-router-dom';
import { roundAndFormatNumber } from '../Common/HelperFunctions';
import StyledSelect from '../Components/StyledSelect';

function HomePage() {
    const { presaleTransactions, presaleRounds, contribute, connectWeb3, disconnectWeb3, web3Connected, allowListEntries, walletAddress, setReferrerString, approveBusd, busdBalance, usdcBalance, usdtBalance, approveUsdt, approveUsdc, usdtAllowance, usdcAllowance, busdAllowance } = useContext(Web3Context);
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedAsset, setSelectedAsset] = useState<Asset>();
    const assets: Asset[] = [
        { id: 0, name: "USDT", icon: "https://exo-gpdwd8ddhvfjhya6.z01.azurefd.net/images/asset/5be9bc4d-524a-49e9-b3dc-c8902f46d4ad.svg", address: "0xdAC17F958D2ee523a2206206994597C13D831ec7" },
        { id: 1, name: "USDC", icon: "https://exo-gpdwd8ddhvfjhya6.z01.azurefd.net/images/asset/5a815f1a-9284-4167-8617-87c7ab67e8e2.svg", address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48" },
        { id: 2, name: "BUSD", icon: "https://exo-gpdwd8ddhvfjhya6.z01.azurefd.net/images/asset/a413bca9-5286-4f2a-8906-f56f05807b90.svg", address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53" },
    ];

    function getTransactionForRound(round: number): PresaleTransaction[] {
        var ret: PresaleTransaction[] = [];
        ret = presaleTransactions.filter(x => x.roundId.toString() == round.toString());
        return ret;
    }

    function getApproveFunction() {
        if (selectedAsset?.id == 0) return approveUsdt;
        else if (selectedAsset?.id == 1) return approveUsdc;
        else if (selectedAsset?.id == 2) return approveBusd;
        else return async () => {};
    }

    function getApprovalAmount() {
        if (selectedAsset?.id == 0) return usdtAllowance;
        else if (selectedAsset?.id == 1) return usdcAllowance;
        else if (selectedAsset?.id == 2) return busdAllowance;
        else return 0;
    }

    useEffect(() => {
        var referrer = searchParams.get("ref");
        // console.log(`REFERRER: ${referrer}`);

        if (referrer != null) {
            setReferrerString(referrer.toLowerCase());
        }
    }, [presaleRounds]);

    useEffect(() => {
        if (assets.length > 0) {
            setSelectedAsset(assets[0]);
        }
    }, []);

    const whitelistedRounds = presaleRounds.filter(x => allowListEntries.find(y => y.roundId == x.number)?.isAllowed && x.closed == false).sort((a, b) => Number(b.special) - Number(a.special));
    const closedRounds = presaleRounds.filter(x => ((x.special && allowListEntries.find(y => y.roundId == x.number)?.isAllowed) || (x.special == false)) && x.closed == true /*&& presaleTransactions.filter(y => y.round == x.number).length > 0*/);

    return (
        <>
            <main className="flex flex-col w-100 flex-1 relative focus:outline-none">
                {/* <h1 className="text-primary-500  text-left text-3xl font-bold">Contributions</h1> */}

                <div className="flex mx-auto mt-5 md:mt-10 drop-shadow-2xl">
                    {/* <img src={ImageExoLogo} className="h-14 -mt-4 flex-col" /> */}
                    {/* <div className="border-l-4 border-white h-full mx-2 md:mx-8 -mt-2"></div> */}
                    <div className="text-primary text-4xl -mt-2 ">PRESALE ROUNDS</div>
                </div>
                <div className="mx-auto text-white mt-4 px-2 drop-shadow-2xl text-center">Use this dashboard to check the status and contribute to the presale rounds. The tokens below are ERC20 (Ethereum) only.</div>

                <StyledSelect otherClasses="text-white mt-4" value={selectedAsset ? { label: selectedAsset.name, value: selectedAsset.name, image: selectedAsset.icon } : undefined} options={assets.map(x => { return { label: x.name, value: x.name, image: x.icon } })} onChange={(value) => setSelectedAsset(assets.find(x => x.name == value) || undefined)} />

                {selectedAsset?.id == 0 ? <div className="mx-auto text-white mt-4 px-2 drop-shadow-2xl"><b>Your USDT balance:</b> ${roundAndFormatNumber(usdtBalance, 2)} USDT.</div> : null}
                {selectedAsset?.id == 1 ? <div className="mx-auto text-white mt-4 px-2 drop-shadow-2xl"><b>Your USDC balance:</b> ${roundAndFormatNumber(usdcBalance, 2)} USDC.</div> : null}
                {selectedAsset?.id == 2 ? <div className="mx-auto text-white mt-4 px-2 drop-shadow-2xl"><b>Your BUSD balance:</b> ${roundAndFormatNumber(busdBalance, 2)} BUSD.</div> : null}


                <div className="w-full mt-6">
                    {
                        whitelistedRounds.length > 0 ? whitelistedRounds.map(r => (
                            <div key={"presaleround_" + r.number}><Round round={r} contributeFunction={contribute} transactions={getTransactionForRound(r.number)} tokenAllowance={getApprovalAmount()} tokenApproveFunction={getApproveFunction()} tokenName={selectedAsset?.name ?? "Loading"} />
                            </div>)) : null
                    }

                    {
                        closedRounds.length > 0 ? closedRounds.map(r => (
                            <div key={"presaleround_" + r.number}><Round round={r} contributeFunction={contribute} transactions={getTransactionForRound(r.number)} tokenAllowance={getApprovalAmount()} tokenApproveFunction={getApproveFunction()} tokenName={selectedAsset?.name ?? "Loading"}/>
                            </div>)) : null
                    }

                    {
                        web3Connected == false ? <div className="mx-auto text-white font-bold p-5 rounded-xl bg-black bg-opacity-20 text-center p-8 px-4 md:px-10 my-8">
                            Connect your wallet to get started.
                        </div> : null
                    }

                    {
                        web3Connected == true && whitelistedRounds.length == 0 ? <div className="mx-auto text-white font-bold p-5 rounded-xl bg-black bg-opacity-20 text-center p-8 px-4 md:px-10 my-8">
                            Unfortunately your wallet is not currently whitelisted for a presale round. <a href="https://t.me/gtruk" target="_blank" className='underline'>Click here</a> to head to our Telegram for information.
                        </div> : null}
                </div>
            </main>
        </>
    );
}

export default HomePage;