var logFilterName = "fetchVipCompoundRate";

export function log(source: string, content: any) {
    var debug = true;
    if (debug == true) {
        if (typeof content == typeof "" || typeof content == typeof "0") {
            console.log("[" + source + "]  " + content);
        }
        else {
            console.log("[" + source + "]  " + JSON.stringify(content));
        }
    }
}

export function round(value: number, precision: number) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function formatNumber(inputNumber: number): string {
    var ret = inputNumber.toString();
    var expr = /\B(?=(\d{3})+(?!\d))/g;
    if (ret.indexOf(".") == -1) { ret = ret.replace(expr, ","); }
    else {
        var split = ret.split(".");
        var left = split[0].replace(expr, ",");
        var right = split[1];
        if (right.length == 1 && right != "0") { right += "0"; }

        ret = `${left}.${right}`;
    }

    return ret;
}

export function roundAndFormatNumber(value: number, precision: number = 2): string {
    var ret = "";

    var rounded = round(value, precision);
    var formatted = formatNumber(rounded);
    ret = formatted;

    return ret;
}

export function convertFromUnixTimestamp(input: number): Date {
    return new Date(input * 1000);
}

export function getYearMonthDayString(date: Date): string {
    var input = new Date(date);

    var ret = input.getFullYear() + "-" + (input.getMonth() + 1).toString().padStart(2, '0') + "-" + input.getDate().toString().padStart(2, '0');
    return ret;
}

export function getDayMonthFullString(date: Date): string {
    var input = new Date(date);

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var ret = `${input.getDate().toString().padStart(2, '0')} ${months[input.getMonth()]}`;
    return ret;
}

export function getYearMonthDayTimeString(date: Date): string {
    var input = new Date(date);
    var suffix = input.getHours() > 12 ? "PM" : "AM";

    var ret = input.getFullYear() + "-" + (input.getMonth() + 1).toString().padStart(2, '0') + "-" + input.getDate().toString().padStart(2, '0') + " " + (input.getHours() % 12).toString().padStart(2, '0') + ":" + input.getMinutes().toString().padStart(2, '0') + " " + suffix;
    return ret;
}

export function addDaysToDate(date: Date, days: number): Date {
    var ret = new Date(date);
    ret.setDate(date.getDate() + days);
    return ret;
}

export function getDurationSeconds(start: Date, end: Date): number {
    var diff = end.valueOf() - start.valueOf() / 1000;
    return diff;
}

export function secondsToDateTime(seconds: number): Date {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(seconds);
    return t;
}

export function dateFromUtcEpoch(seconds: number): Date {
    var ret = new Date(0);
    ret.setUTCSeconds(seconds);
    return ret;
}

//https://bearnithi.com/2019/11/10/how-to-calculate-the-time-difference-days-hours-minutes-between-two-dates-in-javascript/#:~:text=To%20calculate%20the%20minutes%20difference,milliseconds%20(minutes%20*%2060)%20.
export function getTimeUntil(start: Date, end: Date) {
    let diffInSeconds = (end.valueOf() - start.valueOf()) / 1000;

    var ret = { days: 0, hours: 0, minutes: 0, seconds: 0, hasEnded: true };
    if (diffInSeconds > 0) {
        // calculate days
        const days = Math.floor(diffInSeconds / 86400);
        diffInSeconds -= days * 86400;

        // calculate hours
        const hours = Math.floor(diffInSeconds / 3600) % 24;
        diffInSeconds -= hours * 3600;

        // calculate minutes
        const minutes = Math.floor(diffInSeconds / 60) % 60;
        diffInSeconds -= minutes * 60;

        ret = { days: days, hours: hours, minutes: minutes, seconds: diffInSeconds, hasEnded: false };
    }

    return ret;
}

export function abbreviateAddress(walletAddress: string, beforeLength: number = 6, afterLength: number = 4): string {
    return walletAddress.substring(0, beforeLength) + "..." + walletAddress.substring(walletAddress.length - afterLength, walletAddress.length);
}

export class Group<T> {
    key: string;
    members: T[] = [];
    constructor(key: string) {
        this.key = key;
    }
}

export function groupBy<T>(list: T[], func: (x: T) => string): Group<T>[] {
    let res: Group<T>[] = [];
    let group: Group<T> | null = null;
    list.forEach((o) => {
        let groupName = func(o);
        if (group === null) {
            group = new Group<T>(groupName);
        }
        if (groupName != group.key) {
            res.push(group);
            group = new Group<T>(groupName);
        }
        group.members.push(o)
    });
    if (group != null) {
        res.push(group);
    }
    return res
}